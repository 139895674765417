import React from "react";
import Paragraph from "../../../../common/Paragraphs";
import content from "../../config/content";
import { Link } from "react-router-dom";
import { Button } from "antd";
import "./whyChoose.scss";
const { WhyChooseContent } = content;
const { aboutUs } = content;
interface MyComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  // other props if any
  content?: string;
}
const MyComponent: React.FC<MyComponentProps> = ({ content = "", ...restProps }) => {
  return <p {...restProps} dangerouslySetInnerHTML={{ __html: content }} />;
};
function WhyChoose() {
  return (
    <div className='col-2-img about-us whyChoose s container text-center'>
      <h1>{WhyChooseContent.title}</h1>
      <div className='row w100 md'>
        <div className='w50 col-2  whyChoose_img'>
          <img
            src={WhyChooseContent.contents[0].part1?.image}
            alt='instagram diet post'
          />

          <br></br>
        </div>
        <div className='w50 col-2 ctm_p'>
          <MyComponent
            content={WhyChooseContent.contents[0].part1?.para}
            className=''
          />
        </div>
      </div>
      <div className='row w100 md ctm_mobile_2'>
        <MyComponent
          content={WhyChooseContent.contents[1].part2?.para}
          className='w50 col-2 ctm_mobile_2'
        />
        <div className='w50 col-2 ctm_mobile_1 whyChoose_img'>
          <img
            src={WhyChooseContent.contents[1].part2?.image}
            alt='instagram diet post'
          />

          <br></br>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
