import React, { useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Button } from 'antd'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { useSliderReducer } from '../../common/Slider/store/useSliderReducer'
import { useStripeReducer } from '../../common/StripeCheckout/store/useStripeReducer';

import Header from '../../container/Header';
import Routes from './Routes'
import dropemConfig from './config/dropemFitness'
import pageArray from "./Pages";
import content from './config/content';

import Store from './store'

import './App.scss';
import 'antd/dist/antd.css';

function App() {
  const [showMenu, setShowMenu] = useState(false)
  const { dataDispatch, actions: { SHOW_SLIDER } } = useSliderReducer();
  const { actions: { SET_STRIPE_PUBLIC_KEY } } = useStripeReducer()
  const location = useLocation();

  useEffect(
    () => {
      dataDispatch(SET_STRIPE_PUBLIC_KEY, content.stripePublicKey)
    },
    []
  )
  useEffect(() => {
    if (location.pathname.length > 1) {
      const targetParam = location.pathname.substring(1)
      dataDispatch(SHOW_SLIDER, targetParam)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setShowMenu(oldVal => oldVal ? false : false)
  },
    [location]
  )
  const linkRenderer = () => (
    <>
      {links}
      <a href="https://shop.dropemfitness.com">Athletic Apparel</a>
      <li>
        <a
          href="https://calendly.com/dropemathletics"
        >
          <Button className="call-to-action" type="primary" size="large">Schedule a Meeting</Button>
        </a>
      </li>
    </>
  )

  const toggleShowMenu = () => {
    setShowMenu(oldState => !oldState)
  }

  // Render Links from PageArray
  const links = pageArray.map(page => {

    return page.key !== "Forms" && <li key={page.key}><Link to={{ pathname: `/${page.key}` }}>{page.name}</Link></li>
  })

  return (
    <div className={`App CMS ${dropemConfig.rootClassName}`}>
      <Header height={115} {...dropemConfig.header}>
        <Link key="home" to="/" ><img className="logo" src={dropemConfig.logo.img} alt="Logo" /></Link>
        <div className="desktop">
          <ul>
            {linkRenderer()}
          </ul>
        </div>
        <div className="mobile">
          <Button
            className="hamburger"
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleShowMenu}
          />
          <div className={`menu ${showMenu ? 'show' : ''}`}>
            <ul>
              <li>
                <Link key="home" to="/" ><img className="menu-logo" src={dropemConfig.logo.img} alt="Logo" /></Link>
              </li>
              {linkRenderer()}
            </ul>
            <Button
              className="close"
              type="primary"
              icon={<CloseOutlined />}
              onClick={toggleShowMenu}
            />
          </div>
        </div>
      </Header>
      <Routes />
    </div>
  );
}

// eslint-disable-next-line
export default { App, Store };
