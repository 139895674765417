import React from 'react';
import Paragraph from '../../../../common/Paragraphs';
import StripeBtn from '../../../../common/StripeCheckout/StripeBtn'

import content from '../../config/content'

const { dietPlans } = content;

function DietPlans() {
  const listRenderer = (list: any) => <ul>{
    list.map((item: any, index: any) => {
      return <li key={index}>{item}</li>
    })
  }</ul>
  return (
    <div className='col-2-img diet-plans container'>
      <h1>{dietPlans.headerText}</h1>
      <div className='row w100 md'>
        <div className='w50 col-1'>
          <Paragraph parArray={dietPlans.par} />
          {listRenderer(dietPlans.list)}

          <Paragraph parArray={dietPlans.par2} />

          <h3>{dietPlans.list2.title}</h3>
          {listRenderer(dietPlans.list2.bullets)}
        </div>
        <div className='w50 col-2'>
          <a href='https://www.instagram.com/drop_them_fitness/'>
            <img src={dietPlans.ig.src} alt='instagram diet post' />
          </a>
          <br></br>
          {console.log(window.location.hostname)}
          <StripeBtn
            id='workout-plans'
            customClass='call-to-action'
            type='button'
            productId={dietPlans.productId}
          />
        </div>
      </div>
    </div>
  );
}

export default DietPlans;
