import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import IGFeeder from "../../../../common/IGFeeder";
import content from "../../config/content";
import pauseicon from "../../Assets/play.png";
import Nexticon from "../../Assets/arrow-right.png";

import "./mainContent.scss";
interface IMainContent {
  showContent: boolean;
}
interface ISlide {
  type: string;
  content: string;
  title: string;
  description: string;
}
const Slider = ({ slides }: { slides: ISlide[] }) => {
  const [currentSlide, setCurrentSlide] = useState<Number>(0);
  const [videostatus, setvideostatus] = useState<Boolean>(false);
  const videoRef = useRef<any>(null);

  const handlePausePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setvideostatus(false);
    } else {
      videoRef.current.pause();
      setvideostatus(true);
    }
  };

  useEffect(() => {
    //  if (videoRef.current.paused) {
    //    setvideostatus(false);
    //  } else {
    //    setvideostatus(true);
    //  }
    const consol = () => {
      setvideostatus(false);

    }
    consol();
  }, [currentSlide]);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide: any) => (prevSlide + 1) % slides.length);
  };
  const handlePrvSlide = () => {
    setCurrentSlide((prevSlide: any) => (prevSlide - 1) % slides.length);
  };

  return (
    <div className='slider-container'>
      {slides.map((slide: any, index: any) =>
        currentSlide == index ? (
          <div
            key={index}
            className={`slide ${
              index === currentSlide ? "active fade-enter-active" : ""
            }`}>
            {slide.type === "image" && (
              <img
                className='slider_main_img'
                src={slide.content ? slide.content : ""}
                alt={`Slide ${index}`}
                width={500}
              />
            )}
            {slide.type === "video" && (
              <video ref={videoRef} autoPlay onClick={handlePausePlay}>
                <source src={slide.content ? slide.content : ""} />
                Your browser does not support the video tag.
              </video>
            )}
            {console.log(slides.length)}
            <div className='slide-content'>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              {0 == index ? (
                ""
              ) : (
                <button className='prvButton_slider' onClick={handlePrvSlide}>
                  <img src={Nexticon} />
                </button>
              )}
              {slides.length - 1 == index ? (
                ""
              ) : (
                <button className='nextButton_slider' onClick={handleNextSlide}>
                  <img src={Nexticon} />
                </button>
              )}

              {slide.type === "video" ? (
                !videostatus ? (
                  ""
                ) : (
                  <button className='pausebtn_slider' onClick={handlePausePlay}>
                    <img src={pauseicon} />
                  </button>
                )
              ) : (
                ""
              )}
              <button
                onClick={slide.ctaAction}
                className='ant-btn ant-btn-primary ant-btn-lg call-to-action_slider'>
                {slide.ctaText}
              </button>
            </div>
          </div>
        ) : (
          ""
        )
      )}
    </div>
  );
};
export default Slider;