import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from '../Home';
import Canceled from "../../../common/StripeCheckout/components/Canceled";
import Success from "../../../common/StripeCheckout/components/Success";
import pageArray from "../Pages";
import WhyChoose from "../Pages/WhyChoose/index";
import Steps from "../Pages/Steps/index";
import Form from "../Pages/form/index";

function Routes() {
  const renderedRoutes = pageArray.map(page => {
    return <Route exact key={page.key} path={`/${page.key}${page.params}`} component={page.component} />
  })

  return (
    <div className='dropem-fitness'>
      <Switch>
        <Route exact key='home' path='/' component={Home} />
        <Route exact key='WhyChoose' path='/WhyChoose' component={WhyChoose} />
        <Route exact key='home' path='/canceled' component={Canceled} />
        <Route exact key='home' path='/success' component={Success} />
        <Route exact key='Steps' path='/steps' component={Steps} />
        <Route exact key='Form' path='/form' component={Form} />
        {renderedRoutes}
      </Switch>
    </div>
  );
}

export default Routes;
