import React from 'react'
import content from '../../../../config/content'
import StripeBtn from '../../../../../../common/StripeCheckout/StripeBtn'

const { workoutPlans } = content

function OnSite() {
  const listRenderer = (list: any) => <ul>{
    list.map((item: any, index: any) => {
      return <li key={index}>{item}</li>
    })
  }</ul>
  return <div className="workout-plans container">
    <h1>{workoutPlans.onsite.headerText}:</h1>
    <br />
    <StripeBtn
      id='workout-plans'
      customClass="call-to-action"
      type="dropdown"
      productId={workoutPlans.onsite.productId}
    />
    <br />
    <br />
    <h1>{workoutPlans.onsite.title.text}</h1>
    <h2>{workoutPlans.onsite.title2.text}</h2>
    {listRenderer(workoutPlans.onsite.title2.list)}

    <h2>{workoutPlans.onsite.title3.text}:</h2>
    {listRenderer(workoutPlans.onsite.items)}

    <h2>{workoutPlans.onsite.title4.text}</h2>
    {listRenderer(workoutPlans.onsite.items2)}

    <h2>{workoutPlans.onsite.title5.text}</h2>
    {listRenderer(workoutPlans.onsite.items3)}
  </div>
}

export default OnSite
