import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, } from "react-router-dom";
import "./styles/layout.scss"
import "./index.scss";

import BGE from "./App";
import CMS from "./CMS/DropemFit";
// import CMS from "./CMS/Wolf-pack"
// import CMS from "./CMS/Starter"

const targetApp = process.env.REACT_APP_TARGET === 'bge' ? BGE : CMS;
const { App, Store } = targetApp;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
