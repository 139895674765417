import dropemLogo from '../Assets/logo-fix-dimensions.png';
import './dropemStyles.scss';

const pageSetup = {
  rootClassName: 'dropemStyles',
  header: {
    classStyles: 'dropem-header'
  },
  logo: {
    img: dropemLogo
  }
};

export default pageSetup;
