import { isEmpty } from 'lodash'

export function formatPriceOptions(results: any = []) {
  const activePrices = results.filter((item: any) => item.active)
  return activePrices.map((item: any) => {
    return {
      priceId: item.id,
      nickname: item.nickname,
      unitAmount: item.unit_amount,
      currency: item.currency,
      quantity: 1,
      mode: isEmpty(item.recurring) ? 'payment' : 'subscription'
    }
  })
}