import React from 'react'
import { Link } from "react-router-dom";
import { Button } from 'antd'
import IGFeeder from '../../../../common/IGFeeder';
import content from '../../config/content';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "./slider";
import png from "../../Assets/coach-photo.png";


import './mainContent.scss'
interface IMainContent {
  showContent: boolean;
}
function MainContent({ showContent }: IMainContent) {
  const slidesData = content.slidesData;


  return (
    <div className={`main-content ${showContent ? "show" : ""}`}>
      {/* <div className='welcome row md w100 container'>
        <div className='w50 message'>
          <h1>{content.landing.headerText}</h1>
          <p className='motto'>"Acheiving Results with Knowledge"</p>
          <p>{content.landing.par}</p>
          <Link to={content.landing.button.path}>
            <Button className='call-to-action' type='primary' size='large'>
              Get Started
            </Button>
          </Link>
        </div>
        <div className='w50'>
          <img src={content.landing.image[0].src} alt='Renald Thomas' />
        </div>
      </div>
      <IGFeeder /> */}
      <Slider slides={slidesData} />
    </div>
  );
}

export default MainContent;

