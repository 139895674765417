import React from 'react'
import StripeBtn from '../../../../../../common/StripeCheckout/StripeBtn'
import content from '../../../../config/content'


const { workoutPlans } = content

function Online() {
  const listRenderer = (list: any = []) => <ul>{
    list.map((item: any, index: any) => {
      return <li key={index}>{item}</li>
    })
  }</ul>
  return <div className="workout-plans container">
    <h1>{workoutPlans.headerText}:</h1>
    <br />
    <StripeBtn
      id='workout-plans'
      customClass="call-to-action"
      type="dropdown"
      productId={workoutPlans.online.productId}
    />
    <br />
    <br />
    <h1>{workoutPlans.online.title.text}</h1>
    <div className="row">
      <div className="col1">
        <h2>{workoutPlans.online.title2.text}</h2>
        {listRenderer(workoutPlans.online.lists[0].activities)}


        <h2>{workoutPlans.online.title3.text}</h2>
        {listRenderer(workoutPlans.online.lists[1].equipment)}

        <h2>{workoutPlans.online.title4.text}</h2>
        {listRenderer(workoutPlans.online.lists[2].cardio)}
      </div>
    </div>
  </div >
}

export default Online
