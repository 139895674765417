import React from 'react';
import { Tabs } from 'antd';
import OnSite from './components/Onsite'
import Online from './components/Online';

import './workoutPlans.scss'

const { TabPane } = Tabs;

function WorkoutPlans() {
  return (
    <div className="workout-plans" >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Online" key="1">
          <Online />
        </TabPane>
        <TabPane tab="On-Site" key="2">
          <OnSite />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default WorkoutPlans
