import AboutUs from './AboutUs'
import DietPlans from './DietPlans'
import Testimonials from './Testimonials'
import WorkoutPlans from './WorkoutPlans'
import Forms from './Forms'

const pageArray = [
  {
    key: 'about-us',
    params: '',
    component: AboutUs,
    name: 'About Us'
  },
  {
    key: 'diet-plans',
    params: '',
    component: DietPlans,
    name: 'Diet Plans'
  },
  {
    key: 'Testimonials',
    params: '',
    component: Testimonials,
    name: 'Testimonials'
  },
  {
    key: 'coaching',
    params: '',
    component: WorkoutPlans,
    name: 'Coaching'
  },
  {
    key: 'Forms',
    params: '/:formTarget',
    component: Forms,
    name: 'Forms'
  }
]

export default pageArray;
